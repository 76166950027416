import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { useVoyadoLookup } from '@jetshop/flight-voyado';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Redirect } from 'react-router';
import t, { useIntl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import Input from '../../Forms/Input';
// import { ButtonWithLoading } from '../../ui/AuthButton';
import { theme } from '../../Theme';
import {
  ExternalLookupField,
  InputLabelWrapper,
  RequiredFieldsText
} from './VoyadoLookupField';
import RequiredAsterisk from '../../Forms/RequiredAsterisk';
// import loadable from '@loadable/component';
// import LoadingPage from '../../LoadingPage';
import Container from '../../Layout/Container';
import Button from '../../ui/Button';

export const InputLabel = ({ label }) => {
  return (
    <InputLabelWrapper>
      <span>{t(label)}</span>
      <RequiredAsterisk />
    </InputLabelWrapper>
  );
};

export const TextLink = styled(Link)`
  color: #4b543c;
  text-align: right;
  font-size: 0.875em;
  font-weight: 400;
  text-decoration: none;
  align-self: flex-end;
  margin-top: 0.75rem;

  :hover {
    opacity: 0.8;
  }

  &.center-link {
    display: block;
    width: 100%;
    text-align: center;
    margin: 2rem 0 1rem 0;
  }
`;

export const titleStyle = `
  // text-align: start;
  // font-size: 2.5rem;
  // font-weight: 400;
  // line-height: 40px;
  // margin-bottom: 15px;
`;

export const Title = styled.h1`
  ${titleStyle};
`;

export const CustomerStatusHeaderText = styled('p')`
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  line-height: 22.4px;
  color: #202416;
  /* text-align: center; */
  margin-top: 16px;
  margin-bottom: 32px;
  white-space: pre-wrap;

  ${theme.below.sm} {
    text-align: start;
  }
`;

const ActivationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 70%;
  min-width: 300px;
`;

const PasswordContainer = styled('div')`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;

  div {
    margin: 0;
  }

  .text-under {
    display: flex;
    justify-content: space-between;
  }
`;

const SubmitSection = styled('section')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 0;
`;

export const ErrorMessage = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  margin: 1rem 0;
  color: #8a3c10;
`;

function VoyadoLookup({ children, ...rest }) {
  const { ...voyado } = useVoyadoLookup({
    personLookupConfigured: false,
    manualPersonLookup: false
  });
  // const { routes } = useShopConfig();
  const { logIn, loggedIn } = useAuth();
  const t = useIntl();
  const [enteredEmail, setEnteredEmail] = React.useState('');

  if (loggedIn) {
    return <Redirect to="/mina-sidor" />;
  }

  if (voyado.isAdditionalDataRequired) {
    return (
      <Redirect
        to={{
          pathname: '/skapa-konto',
          state: {
            customer: {
              enteredEmail: enteredEmail,
              ...voyado.customer
            },
            status: {
              isAdditionalDataRequired: voyado.isAdditionalDataRequired
            }
          }
        }}
      />
    );
  }

  if (voyado.isNonExistingCustomer || voyado.isPersonLookupFailed) {
    return (
      <Redirect
        to={{
          pathname: '/skapa-konto',
          state: {
            customer: { ...voyado?.customer, externalId: '' },
            status: {
              isNonExistingCustomer: voyado.isNonExistingCustomer
            }
          }
        }}
      />
    );
  }

  if (voyado.isInActivation) {
    const {
      isActivationRequired,
      isActivationPending,
      isActivationSuccess
    } = voyado;
    return (
      <ActivationWrapper>
        {isActivationSuccess && <Title>{t('Account activated')}</Title>}
        {(isActivationRequired || isActivationPending) && (
          <Title>{t('Activate account')}</Title>
        )}
        <CustomerStatusHeaderText>
          {isActivationRequired &&
            t(
              'You exist as an member, click to activate your account. We will send you an email with a link on how to set your password.'
            )}
          {isActivationSuccess &&
            t('We have successfully activated your account.')}
          {isActivationPending && t('Activating account')}
        </CustomerStatusHeaderText>
        <Button
          dark={true}
          disabled={isActivationPending}
          loading={isActivationPending}
          onClick={() => {
            if (isActivationRequired) {
              voyado.activate();
            } else {
              logIn(voyado.customer.token);
            }
          }}
        >
          {isActivationRequired && t('Activate')}
          {isActivationPending && t('Activating')}
          {isActivationSuccess && t('Login')}
        </Button>
      </ActivationWrapper>
    );
  }

  if (voyado.isPreExistingCustomer) {
    return (
      <>
        <Head data={{ title: t('Login') }} />
        <LogInFormProvider
          redirect="mina-sidor"
          initialEmail={voyado?.customer?.emailAddress.masked}
        >
          {({ globalError, isSubmitting, isValid }) => (
            <>
              <Title>{t('Login')}</Title>
              <CustomerStatusHeaderText>
                {t('We found your account, enter your password to continue.')}
              </CustomerStatusHeaderText>
              <Input
                type="email"
                name="email"
                placeholder={t('Enter email address')}
                label={<InputLabel label="E-mail address" />}
                wrapperClassName="voyado-field"
              />
              <PasswordContainer>
                <Input
                  type="password"
                  name="password"
                  placeholder={t('Password')}
                  label={<InputLabel label="Password" />}
                  wrapperClassName="voyado-field"
                />
                <div className="text-under">
                  <RequiredFieldsText />
                  <TextLink to="/forgot-password">
                    {t('Forgot password?')}
                  </TextLink>
                </div>
              </PasswordContainer>
              <SubmitSection>
                <Button
                  style={{
                    width: '100%',
                    textTransform: 'uppercase',
                    maxWidth: '100%'
                  }}
                  dark={true}
                  type="submit"
                  disabled={!isValid}
                  loading={isSubmitting}
                  loadingText={t('Hold on a moment...')}
                >
                  {t('Log in')}
                </Button>
                {globalError && <ErrorMessage>{globalError}</ErrorMessage>}
              </SubmitSection>
            </>
          )}
        </LogInFormProvider>
      </>
    );
  }

  return (
    <>
      <ExternalLookupField {...voyado} setEnteredEmail={setEnteredEmail}>
        {voyado.isCountryNotValid && (
          <ErrorMessage>
            {t('Email address already registered for another country')}
          </ErrorMessage>
        )}

        {Object.values(voyado.error).some(Boolean) && (
          <ErrorMessage>
            {t('Something went wrong. Please try again')}
          </ErrorMessage>
        )}
      </ExternalLookupField>
    </>
  );
}

export const VoyadoFlowWrapper = styled(Container)`
  align-items: center;
  margin-bottom: 4rem;

  *:focus-visible {
    outline: 2px solid ${theme.colors.secondarygrey};
  }

  input[type='checkbox'] {
    position: absolute;
    z-index: -1;
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    margin: 0 -1px -1px 0;
    overflow: hidden;
    padding: 0;
    display: block !important;

    &:focus-visible + span {
      outline: 2px solid ${theme.colors.secondarygrey};
    }
  }

  ${theme.below.sm} {
  }
`;

function VoyadoLookupContainer() {
  return (
    <VoyadoFlowWrapper>
      <VoyadoLookup />
    </VoyadoFlowWrapper>
  );
}

export default VoyadoLookupContainer;
